import type { ComponentType } from "react"
import { addPropertyControls, ControlType } from "framer"
import React, { useEffect, useState } from "react"

function getDefaultTheme() {
    return window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: light)").matches
        ? "light"
        : "dark"
}

function setInitialState() {
    const storedTheme = localStorage.getItem("currentToggleState")
    if (storedTheme !== null) {
        return storedTheme === "dark"
    } else {
        localStorage.setItem("currentToggleState", "dark")
        return false
    }
}

export function themeSwicther(Component): ComponentType {
    return (props) => {
        const [isOn, setIsOn] = useState(setInitialState())

        const toggle = () => {
            const newToggleState = !isOn ? "dark" : "light"
            setIsOn(!isOn)
            localStorage.setItem("currentToggleState", newToggleState)
            if (typeof window !== "undefined") {
                window.dispatchEvent(new CustomEvent("themeChange"))
            }
        }

        useEffect(() => {
            const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)")
            mediaQuery.addListener((e) => {
                const newTheme = e.matches ? "dark" : "light"
                localStorage.setItem("defaultTheme", newTheme)
                localStorage.setItem("currentToggleState", newTheme)
                setIsOn(newTheme === "dark")

                if (typeof document !== "undefined") {
                    const styleTag = document.getElementsByTagName("style")[0]
                    const newCSS = styleTag.innerHTML.replace(
                        /prefers-color-scheme: \w+/,
                        `prefers-color-scheme: ${
                            (newTheme === "dark" && newTheme === "dark") ||
                            (newTheme === "light" && newTheme === "light")
                                ? "dark"
                                : "light"
                        }`
                    )
                    styleTag.innerHTML = newCSS
                }

                window.dispatchEvent(new CustomEvent("themeChange"))
            })
        }, [])

        useEffect(() => {
            if (
                typeof window !== "undefined" &&
                typeof document !== "undefined"
            ) {
                const currentToggleState = isOn ? "dark" : "light"
                const defaultTheme =
                    localStorage.getItem("defaultTheme") || getDefaultTheme()

                const styleTag = document.getElementsByTagName("style")[0]
                const newCSS = styleTag.innerHTML.replace(
                    /prefers-color-scheme: \w+/,
                    `prefers-color-scheme: ${
                        (currentToggleState === "dark" &&
                            defaultTheme === "dark") ||
                        (currentToggleState === "light" &&
                            defaultTheme === "light")
                            ? "dark"
                            : "light"
                    }`
                )
                styleTag.innerHTML = newCSS

                localStorage.setItem("currentToggleState", currentToggleState)

                document.documentElement.setAttribute(
                    "data-theme",
                    currentToggleState
                )
            }
        }, [isOn])

        const [isClient, setIsClient] = useState(false)

        useEffect(() => {
            setIsClient(true)
        }, [])

        return <Component {...props} onClick={toggle} />
    }
}
